<template>
  <div class="pagingBox" :style="{ 'justify-content': $slots.batch ? 'space-between' : 'flex-end' }">
    <!-- 父组件需求
      <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
      页数与页面数据数量发生改变调用的父组件函数
      updateData(val, status) {
        if (status == 0) {
          this.pageNum = val
        } else {
          this.page = val
        }
        重新获取数据
        this.getInfo()
      } -->
    <slot name="batch"></slot>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="pageNum"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :page-sizes="pageSize"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 100,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageNum: {
      type: Number,
      default: 10,
    },
    style: {
      type: Number,
      default: 0,
    },
    isMin: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pageSize: [5, 10, 13, 15,18, 20, 50, 100],
    };
  },
  methods: {
    handleSizeChange(val) {
      this.page = val;
      this.$emit('updatePageNum', val, 0);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.$emit('updatePageNum', val, 1);
    },
    changeSize() {
      this.pageSize = [48];
    },
    changeMin() {
      this.pageSize = [5];
    },
  },
  watch: {
    style: {
      handler() {
        if (this.style == 1) this.changeSize();
      },
      immediate: true,
    },
    isMin: {
      handler() {
        if (this.isMin == 1) this.changeMin();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.pagingBox {
  min-height: 48px;
  overflow: hidden;
  background-color: #f8f8fa;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 14px;
  justify-content: space-between;
}
</style>
