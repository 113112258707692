<template>
  <div class="user-info" :style="{ justifyContent: center ? 'center' : 'flex-start' }">
    <el-image :style="{ borderRadius: square ? 'none' : '50%' }" :src="avatar ? avatar : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/orderDefault.png'"></el-image>
    <div class="name">{{ name || '--' }}</div>
  </div>
</template>

<script>
export default {
  props: ['name', 'avatar', 'center', 'square'],
};
</script>

<style lang="less" scoped>
.user-info {
  display: flex;
  align-items: center;

  .el-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .name {
    max-width: calc(100% - 50px);
    text-align: left;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;
  }
}
</style>
